<template>
  <v-container>
    <div class="pa-2">
      <span class="text-h5">News & Promo</span>
    </div>
    <div class="pa-2">
      <v-btn color="pink" dark top right @click="newActivity()">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div>
    <v-flex>
      <v-layout wrap>
        <v-card class="ma-2 pa-0" style="width:300px;" v-for="(obj, i) in activities" :key="i">
          <v-card-text style="height:250px">
            <v-img max-width="100%" height="180px" gradient 
            :src="$store.state.config.mediaHost + obj._id + '/thumbnail/' + obj.thumbnail.name" class="rounded"></v-img>
            <div class="pt-2">
            <span class="font-weight-medium">{{obj.title}}</span><br>
            <v-row><v-col>
            <span class="caption">{{obj.date}}</span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col>
            <span class="caption text-right" :class="(obj.is_active)?'aktif':'nonaktif'">{{(obj.is_active)?'Aktif':'Nonaktif'}}</span>
            </v-col></v-row>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-menu top left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="editActivity(obj._id)">
                  <v-list-item-title>Update Content</v-list-item-title>
                </v-list-item>
                <v-list-item @click="openGalleriesDlg(obj._id)">
                  <v-list-item-title>Update Galleries</v-list-item-title>
                </v-list-item>
                <!-- <v-list-item>
                  <v-list-item-title>Add Options</v-list-item-title>
                </v-list-item> -->
              </v-list>
            </v-menu>
          </v-card-actions>
        </v-card>

      </v-layout>
    </v-flex>
    <v-dialog v-model="dialog" scrollable width="800" persistent>
      <v-card>
        <v-card-title>New Content</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 500px;">
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-flex py-2>
              <v-layout wrap>
                <v-flex xs12 md12 px-2>
                  <span class="">Judul (Max. 50 karakter)</span>
                  <v-text-field maxlength=50 filled rounded dense v-model="activity.title" :rules="reqRule" required hide-details="auto"></v-text-field>
                </v-flex>
                <v-flex xs12 md12 px-2 py-2>
                  <span class="">Tanggal</span>
                  <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40"
                    transition="scale-transition" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="activity.date" prepend-icon="mdi-calendar" filled rounded dense hide-details="auto"
                        readonly v-bind="attrs" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="activity.date" @input="menu2 = false"></v-date-picker>
                  </v-menu>                  

                </v-flex>
                <v-flex xs12 md12 px-2 py-2>
                  <span class="">Konten</span>
                  <v-textarea filled rounded dense v-model="activity.description" rows="6" hide-details="auto"></v-textarea>
                </v-flex>
                <v-flex xs12 md12 px-2 py-2>
                  <span class="">Thumbnail Kegiatan</span>
                  <v-file-input filled rounded dense 
                  @change="onFileChanged" v-model="vThumbnail"
                  :rules="fileRules" accept="image/*" show-size counter></v-file-input>
                </v-flex>
                <v-flex xs12 md12 px-2>
                  <!-- <span class="">Aktif</span> -->
                  <v-switch v-model="activity.is_active" dense hide-details="auto" :label="(activity.is_active)?'Aktif':'Tidak Aktif'"></v-switch>
                </v-flex>

              </v-layout>
            </v-flex>
          </v-form>          
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
          <v-btn color="blue darken-1" text @click="saveActivity()">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <gallery-update ref="gallery_update" />
    <v-overlay :value="onProgress" style="z-index:999">
        <v-progress-circular :size="50" color="amber" indeterminate></v-progress-circular>
    </v-overlay>  

  </v-container>
</template>
<script>
import GalleryUpdate from '@/components/GalleryUpdate'
export default {
  components: {
    GalleryUpdate,
  },  

  data: () => ({
    drawer: true,
    dialog: false,
    menu2: false,
    valid: true,
    activity: {
        // thumbnail:{},
        // minimum_date: 2,
        // is_active: 1
    },
    thumbnail: '',
    fileRules: [
      v => !v || v.size < 102400 || 'Tidak boleh lebih dari 100KB!'
    ],
    onProgress: false,
    vThumbnail: null,
    reqRule: [v => !!v || 'This field is required']
  }),
  methods:{
    validate () {
      this.$refs.form.validate()
    },
    newActivity() {
      this.dialog = true
      this.vThumbnail = null
      this.activity = {thumbnail:'', is_active:true}
    },
    async saveActivity() {
      await this.$refs.form.validate();
      // console.log(this.valid)
      if (this.valid){
        this.onProgress = true
        var date = new Date();
        var isoDateTime = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString();
        this.activity.datetime = isoDateTime
        this.$store.dispatch('newspromo/postObj', this.activity).then(response=> {
          if (response.result.ok) {
            this.$store.dispatch('newspromo/getObjs').then(()=> {
              this.onProgress = false
            })
            this.activity = {thumbnail:''}
            this.onProgress = false
          }
        })
        this.dialog = false
      }
      else {
        alert('Form error! Periksa kolom yang perlu diisi!')
      }
    },
    editActivity(_id) {
      this.onProgress = true
      this.dialog = true
      this.vThumbnail = null
      this.$store.dispatch('newspromo/getObj', _id).then(response => {
        this.activity = response
        if (this.activity.thumbnail) {
          this.toDataURL(this.$store.state.config.mediaHost + this.activity._id + '/thumbnail/' + this.activity.thumbnail.name).then(response => {
            this.activity.thumbnail.base64 = response
            console.log(this.activity)
          })
          
        }
        this.onProgress = false
      })
    },
    toDataURL(url) { 
        return new Promise((resolve) => {
            fetch(url).then(response => response.blob())
            .then(blob => {
                this.getBase64(blob).then((response)=>{
                    resolve(response)
                })
            })
        })
    },
    getBase64(file) {
        return new Promise((resolve, reject) => {
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                resolve(reader.result);
            };
            reader.onerror = function (error) {
                reject('Error: ', error);
            };
        })
    },
    onFileChanged(image) {
      if (!image) return
      this.getBase64(image).then((response)=>{
          var temp = {}
          temp.base64 = response
          temp.name = image.name
          temp.size = image.size
          temp.type = image.type
          temp.lastModified = image.lastModified
          this.activity.thumbnail = temp
          console.log(this.activity)
      })

    },
    openGalleriesDlg(_id){
      this.$refs.gallery_update.openDialog(_id)
    },

  },
  created() {
    this.onProgress = true
    this.$store.dispatch('newspromo/getObjs').then(()=> {
      this.onProgress = false
    })
  },
  computed: {
    activities() {
      return this.$store.state.newspromo.objs;
    },
  }
}
</script>

<style>
.v-text-field fieldset, .v-text-field .v-input__control, .v-text-field .v-input__slot {
    border-radius: 7px!important;
}

#my-strictly-unique-vue-upload-multiple-image {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
 
h1, h2 {
  font-weight: normal;
}
 
ul {
  list-style-type: none;
  padding: 0;
}
 
li {
  display: inline-block;
  margin: 0 10px;
}
 
a {
  color: #42b983;
}

.v-input--selection-controls .v-input__slot > .v-label, .v-input--selection-controls .v-radio > .v-label {
  font-size: 0.8em;
}
</style> 

<style scoped>
.aktif {
  background-color: #60fa72;
  color: rgb(17, 131, 17);
  padding: 3px;
  font-weight:500;
}
.nonaktif {
  background-color: rgb(218, 218, 218);
  color: rgb(97, 97, 97);
  padding: 3px;
  font-weight:500;
}
</style>

<style lang="scss">
$gap: 20px;

.videos {
  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-left: 0;
    margin: {
      top: 0;
      bottom: 0;
    }
    list-style: none;
  }

  &__item {
    width: calc(50% - #{$gap / 2});

    &:nth-child(n + 3) {
      margin-top: $gap;
    }
  }
}
</style>
